.flashcardsRoot,
.flashcardsRoot > * {
    box-sizing: border-box;
}

.flashcardsRoot {
    font-family: sans-serif;
    padding: 20px;
}

.flashcards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

@media (max-width: 800px) {
    .flashcards {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .flashcards {
        grid-template-columns: 1fr;
    }
}

.flashcards div {
    border: 1px solid #e7e7e7;
    background-color: #f7f7f7;
    border-radius: 7px;
    aspect-ratio: 2 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    cursor: pointer;
}

.flashcards div.selected {
    border: 1px solid #e03131;
    background-color: #e03131;
    color: #fff;
    font-weight: bold;
}
