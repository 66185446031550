@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Quicksand:wght@500;700&display=swap');

.travelListRoot {
    display: flex;
}

.travelListRoot > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.travelListSubroot {
    color: #5a3e2b;
    font-family: 'Quicksand';
    font-weight: 500;
}

.app {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
}

.travelListRoot h1 {
    text-align: center;
    background-color: #f4a226;
    font-family: 'Monoton';
    font-size: 10vw;
    text-transform: uppercase;
    font-weight: 400;
    word-spacing: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: -5px;
    padding: 1rem 0;
}

.add-form {
    background-color: #e5771f;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
@media (max-width: 600px) {
    .add-form {
        flex-direction: column;
        align-items: flex-start;
    }
}

.travelListRoot h3 {
    margin-right: 0.2rem;
}

.travelListRoot button,
.travelListRoot select,
.travelListRoot input {
    background-color: #ffebb3;
    color: #5a3e2b;
    font-family: inherit;
    border: none;
    border-radius: 10rem;
    padding: 0.5rem 1.2rem;
    font-weight: 700;
    cursor: pointer;
}

.add-form button {
    text-transform: uppercase;
    background-color: #76c7ad;
}

.list {
    background-color: #5a3e2b;
    color: #ffebb3;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2.2rem;
    align-items: center;
}

.actions {
    display: flex;
    gap: 1rem;
}

@media (max-width: 600px) {
    .actions {
        flex-direction: column;
    }
}

.actions button,
.list select {
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0.8rem;
}

.list ul {
    list-style: none;
    width: 80%;
    overflow: hidden;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.2rem;
    justify-content: center;
    align-content: start;
}

.list li {
    display: flex;
    align-items: center;
}

.travelListRoot li[type='checkbox'] {
    height: 2rem;
    width: 2rem;
    accent-color: #e5771f;
}

.list li button {
    cursor: pointer;
    background: none;
    border: none;
    transform: translateY(2px);
}

.stats {
    background-color: #76c7ad;
    text-align: center;
    font-weight: 700;
    padding: 2rem 0.5rem;
}

/* Hide the default checkbox */
input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ffebb3;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

/* Add a checkmark */
.custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

/* When the checkbox is checked */
input[type='checkbox']:checked + .custom-checkbox {
    background-color: #e5771f; /* Change this color to what you need */
}

/* Show the checkmark when the checkbox is checked */
input[type='checkbox']:checked + .custom-checkbox::after {
    display: block;
}

/* Optional: add focus styles */
input[type='checkbox']:focus + .custom-checkbox {
    box-shadow: 0 0 3px 2px rgba(0, 123, 255, 0.25);
}

/* Style the label */
label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* Space between the checkbox and the label text */
.label-text {
    margin-left: 8px;
}
