.accordionRoot > * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.accordionRoot {
    font-family: sans-serif;
    color: #343a40;
    line-height: 1;
}

.accordionRoot .accordion {
    width: 700px;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (max-width: 700px) {
    .accordionRoot .accordion {
        width: 90%;
    }
}

.accordionRoot .item {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 20px 24px;
    padding-right: 48px;
    cursor: pointer;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    background-color: #fff;

    display: grid;
    grid-template-columns: auto 1fr 1rem;
    column-gap: 24px;
    row-gap: 32px;
    align-items: center;
}

.accordionRoot .number {
    font-size: 24px;
    font-weight: 500;
    color: #ced4da;
}

.accordionRoot .title,
.accordionRoot .icon {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.8rem;
}

.accordionRoot .content-box {
    grid-column: 2 / -1;
    padding-bottom: 16px;
    line-height: 1.6;
}

.accordionRoot .content-box ul {
    color: #868e96;
    margin-left: 16px;
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* OPEN STATE */
.accordionRoot .open {
    border-top: 4px solid #087f5b;
}

.accordionRoot .open .number,
.accordionRoot .open .title {
    color: #087f5b;
}

.accordionRoot ul {
    list-style: disc;
}
