:root {
    --color-lightest: #fff4e6;
    --color-light: #ffe8cc;
    --color-medium: #ffa94d;
    --color-dark: #ff922b;
}

.eatnsplitRoot > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.eatnsplitRoot {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100vh;
    color: #495057;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eatnsplitRoot .app {
    min-height: 66vh;
    align-items: start;
}

.eatnsplitRoot .button {
    background-color: var(--color-medium);
    color: #343a40;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 7px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.4rem;
}

.eatnsplitRoot .button:hover {
    background-color: var(--color-dark);
}

.eatnsplitRoot .sidebar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

.eatnsplitRoot .sidebar li {
    display: grid;
    grid-template-columns: 0.6fr 2fr 1fr;
    column-gap: 0.5rem;
    align-items: center;

    padding: 1.2rem;
    border-radius: 7px;
    transition: 0.5s;
}

.eatnsplitRoot .selected,
.eatnsplitRoot .sidebar li:hover {
    background-color: var(--color-lightest);
}

.eatnsplitRoot .sidebar li img {
    border-radius: 50%;
    width: 90%;
    grid-row: span 2;
}

.eatnsplitRoot .sidebar li h3 {
    grid-row: 1;
    grid-column: 2;
}

.eatnsplitRoot .sidebar li p {
    grid-row: 2;
    grid-column: 2;
}

.eatnsplitRoot .sidebar li .button {
    grid-row: span 2;
    grid-column: 3;
}

.eatnsplitRoot .sidebar > .button {
    float: right;
    margin-right: 1.2rem;
}

.eatnsplitRoot .green {
    color: #66a80f;
}

.eatnsplitRoot .red {
    color: #e03131;
}

.eatnsplitRoot form {
    font-size: 1.6rem;
    align-items: center;

    background-color: var(--color-lightest);
    border-radius: 7px;
}

.eatnsplitRoot .form-add-friend {
    margin-bottom: 1.6rem;
}

.eatnsplitRoot .form-add-friend,
.eatnsplitRoot .form-split-bill {
    padding: 1rem 0 1rem 0;
}

.eatnsplitRoot label {
    font-weight: 500;
}

.eatnsplitRoot label::first-letter {
    display: inline-block;
    margin-right: 4px;
    font-size: 1.8rem;
}

.eatnsplitRoot input,
.eatnsplitRoot select {
    font-family: inherit;
    color: inherit;
    font-size: 1.5rem;
    /* padding: 0.7rem; */
    text-align: center;
    border: 1px solid var(--color-light);
    border-radius: 4px;
    transition: 0.3s;
    display: block;
    width: 85%;
}

.eatnsplitRoot input:focus,
.eatnsplitRoot select:focus {
    outline: none;
    border: 1px solid var(--color-dark);
}

.eatnsplitRoot form .button {
    margin-top: 1.5rem;
    grid-column: 2;
}

.eatnsplitRoot form h2 {
    grid-column: 1 / -1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    margin-bottom: 1.6rem;
}

.eatnsplitRoot .add-button {
    margin-bottom: 1rem;
}

@media (min-width: 1000px) {
    .eatnsplitRoot .app {
        display: grid;
        grid-template-columns: 34rem auto;
        column-gap: 2rem;
    }

    .eatnsplitRoot .sidebar li {
        grid-template-columns: 4.8rem 1fr auto;
        column-gap: 1.6rem;
    }

    .eatnsplitRoot .sidebar li img {
        width: 100%;
    }

    .eatnsplitRoot form {
        display: grid;
        gap: 1.2rem;
    }

    .eatnsplitRoot .form-add-friend {
        grid-template-columns: 1fr 1.5fr;
        margin-bottom: 1.6rem;
        padding: 1.2rem;
    }
    .eatnsplitRoot .form-split-bill {
        grid-template-columns: 1fr 10rem;
        padding: 3.2rem 4rem;
    }
}

@media (min-width: 1200px) {
    .eatnsplitRoot .app {
        grid-template-columns: 34rem 40rem;
    }
}
