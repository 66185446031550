@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.profileCardRoot {
    font-family: 'IBM Plex Mono', sans-serif;
    background-color: #f7f7f7;
}

.profileCardRoot h1 {
    margin-bottom: 12px;
}

.profileCardRoot em {
    font-size: 0.7em;
}

.card {
    max-width: 450px;
    margin: 40px;
    border: 4px solid #222;
}

.card::after {
    content: '';
    display: block;
}

.avatar {
    width: 100%;
    display: block;
}

.data {
    padding: 32px;
    padding-top: 24px;
}

.skill-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
}

.skill {
    padding: 2px 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.flags {
    float: right;
    margin: 0.3rem 0.5rem 0 0;
}
