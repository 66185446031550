* {
    box-sizing: border-box;
}

.stepsRoot {
    font-family: sans-serif;
    color: #333;
}

.steps {
    max-width: 600px;
    background-color: #f7f7f7;
    border-radius: 7px;
    padding: 25px 100px;
    margin: 100px auto;
}

.numbers {
    display: flex;
    justify-content: space-between;
}

.numbers > div {
    height: 40px;
    aspect-ratio: 1;
    background-color: #e7e7e7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.numbers .active {
    background-color: #7950f2;
    color: #fff;
}

.message {
    text-align: center;
    font-size: 20px;
    margin: 40px 0;
    font-weight: bold;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons button {
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: bold;

    display: flex;
    align-items: center;
    gap: 10px;
}

.buttons button span {
    font-size: 16px;
    line-height: 1;
}

.stepsRoot h3 {
    margin: 0;
    text-transform: uppercase;
}

.close {
    /* position: absolute; */
    /* top: 16px; */
    /* right: 16px; */
    float: right;
    /* margin-top: -3.5rem; */
    margin-right: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 40px;
    color: inherit;
}

.close:hover {
    color: #7950f2;
}

hr {
    clear: both;
}
